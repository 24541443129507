
import { defineComponent } from 'vue'
import Go2Jen from './views/Go2Jen.vue'

export default defineComponent({
  name: 'App',
  components: {
    Go2Jen
  }
})
